.disruptions-date-picker {
  border-radius: 0.1rem;
  border: none;
  box-shadow: 0 0 5px 0 rgba(192, 192, 192, 0.5);
  max-width: 100%;
}

// Date Picker - Weekends Background Colours
.react-datepicker {
  &__header {
    background: none;
    border: none;
  }

  // DAY STYLES
  &__day,
  &__day-name {
    padding: 8px;
    width: 38px;
    height: 38px;
    margin: 0;
    line-height: 1.2;
  }

  &__day-name {
    border-bottom: solid 1px #979797;
  }

  &__day--weekend {
    position: relative;
    border: 0;
    z-index: 1;
    background-color: #f5f5f5;
  }

  // Active
  &__day--selected,
  &__day:hover {
    border-radius: 50px;
    background-color: #e2cee7;
    color: #333333;
  }

  // Today State
  &__day--today {
    font-weight: normal;
  }

  // Bold the select date font
  &__day--selected {
    font-weight: bold;
  }

  // WEEK STYLES
  &__week {
    padding: 0px;
    margin: -1px;
  }

  // MONTH STYLES
  &__current-month {
    font-size: 18px;
    margin: 20px 0;
  }

  // Prev & Next Icons
  &__navigation {
    height: 12px;
    top: 30px;
  }
  &__navigation--previous {
    border-right-color: #111111;
    left: 30px;
  }
  &__navigation--next {
    border-left-color: #111111;
    right: 30px;
  }
}
