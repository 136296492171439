@import '../../../assets/styles/vars';
@import '../../../assets/styles/mixins';

// Default tray styling
.tray {
  z-index: 1;
  background-color: white;
  width: 100%;
  position: absolute;
  height: 100%;
  transition: top 0.35s ease;
  overflow: hidden;

  // On desktop/bigger displays
  @media only screen and (min-width: 768px) {
    position: absolute;
    top: 28px;
    left: 48px;
    max-width: 414px;
    width: 100%;
    height: auto;
    max-height: 60%;
    overflow: auto;
  }

  // The tray on map displays different to the normal as it snaps to the left
  &.mapTray {
    @media only screen and (min-width: 768px) {
      border: 0;
      border-radius: 0;
      height: 100%;
      position: relative;
      left: 0;
      top: 0;
      max-height: 100%;
    }
  }
}

// class used by Swipe component
.swipeTrayWrapper {
  display: block;
  height: 100%;
  width: 100%;
  position: relative;
  transition: top 0.35s ease; // add slight delay to account for minor state change delay

  &.trayIsOpen {
    overflow: auto;
  }
}

// The drawer handle/line at top of tray
.drawerHandle {
  height: 10px;
  position: relative;

  &:before {
    content: '';
    display: block;
    height: 3px;
    width: 90px;
    border-radius: 1.5px; // must be half of the height
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #393033;
  }

  p {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
    border: 0;
  }
}
