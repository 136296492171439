@import '~assets/styles/vars';
@import '~assets/styles/mixins';

.headerWrapper {
  background-color: get-color(background);

  .breadcrumb {
    @media all and (max-width:997px) {
      display: none;
    }
  }
}

@media all and (max-width: 410px) {
  .h1 {
    @include type-setting(3);
    line-height: initial;
  }
  .listMapBtn {
    padding: $size-xs;
  }
}
