@import '~assets/styles/vars';
@import '~assets/styles/mixins';

.affectedRoute {
  background-color: #d4351c;
  border-radius: 5px;
  color: white;
  text-transform: capitalize;
  font-weight: bold;
  padding: 4px 6px 5px 6px;
  margin: 5px 0px;
  list-style-type: none;
  width: fit-content;
  margin-right: $size-sm;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
