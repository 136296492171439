.resetBtn {
  background: none!important;
  border: 0!important;
  border-radius: 0!important;

  &:hover,
  &:active,
  &:focus {
    background: none!important;
  }
}
