@import '~assets/styles/vars';
@import '~assets/styles/mixins';

.favButton {
  display: inline-block;
  margin-right: $size-sm;
  width: auto;

  .starIconBtn {
    background: none;
    border: 0;
    display: block;
    margin: $size-xsm auto 0;
    padding: 0;
    text-decoration: none;

    .starIcon {
      cursor: pointer;
      fill: get-color(secondary);
      height: 25px;
      width: 25px;
    }
  }

  &.favButtonNarrow {
    display: block;
    margin-bottom: 0;
    margin-top: $size-xsm;

    .starIconBtn {
      vertical-align: middle;
      display: inline-block;
      margin: 0 0 0 $size-md;
    }
  }

  &.favButtonInline {
    position: relative;

    .starIconBtn {
      position: absolute;
      transform: translate(5px, -5px);
      margin: 0;
    }
  }
}
