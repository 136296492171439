@import '~assets/styles/vars';
// This ensures the tray is hidden at the bottom of the page
.disruptionsContainer {
  position: relative;
  overflow: hidden;
  background-color: white;

  @media all and (min-width: 768px) {
    min-height: 500px;
  }
}
