// Vars
@import '~assets/styles/vars';

// Gives the listview a nice little padding from the tray on desktop devices
.list {
  @media only screen and (min-width: $breakpoint-md) {
    padding-left: $size-md;
  }
}

.tray {
  height: auto;
  max-height: 100%;
  margin-bottom: $size-lg;
  position: initial;

  @media only screen and (min-width: $breakpoint-md) {
    left: 0;
    margin: 0;
    position: sticky;
  }
}
